.exercise-of-week-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.exercise-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 2rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #2d3748;
}

.form-group select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background-color: white;
    font-size: 0.9rem;
}

.weekly-exercises-section {
    margin-top: 3rem;
}

.weekly-exercises-section h2 {
    color: #2d3748;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #e2e8f0;
}

.exercises-list {
    display: grid;
    gap: 1rem;
}

.exercise-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.exercise-info h3 {
    margin: 0;
    color: #2d3748;
    font-size: 1.1rem;
}

.exercise-info p {
    margin: 0.5rem 0 0;
    color: #4a5568;
}

.exercises-list.past .exercise-item {
    opacity: 0.7;
}

.delete-button {
    background: none;
    border: none;
    color: #a0aec0;
    padding: 0.5rem;
    cursor: pointer;
    transition: color 0.2s;
}

.delete-button:hover {
    color: #e53e3e;
    color: var(--text-light);
}

.submit-button {
    background-color: var(--primary-color);
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.submit-button:hover {
    background-color: var(--primary-color);
}

.share-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.share-link input {
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-size: 0.9rem;
    width: 300px;
    background-color: #f8fafc;
    color: #4a5568;
}

.copy-button {
    background: none;
    border: none;
    color: var(--primary-color);
    padding: 0.5rem;
    cursor: pointer;
    transition: color 0.2s;
}

.copy-button:hover {
    color: var(--primary-color);
}

.copy-button i {
    font-size: 1rem;
}

.no-exercises {
    text-align: center;
    padding: 2rem;
    background: #f8fafc;
    border-radius: 8px;
    color: #64748b;
    font-style: italic;
} 