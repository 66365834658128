.media-gallery {
    margin-top: 2rem;
    padding: 1rem;
}

.media-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 1rem;
    padding-right: 1rem;
}

.media-item {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.2s;
}

.media-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.media-item img,
.media-item video {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.audio-container {
    width: 100%;
    padding: 2rem;
    background: #f8f9fa;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    position: relative;
    z-index: 1;
    min-height: 150px;
}

.audio-container i {
    font-size: 2rem;
    color: #6c757d;
}

.media-item audio {
    width: 100%;
    position: relative;
    z-index: 2;
    min-height: 40px;
    margin: 10px 0;
}

.media-item audio::-webkit-media-controls {
    position: relative;
    z-index: 3;
}

.media-name {
    margin: 0.5rem 0 0 0;
    text-align: center;
    color: #333;
    font-size: 0.9rem;
    word-break: break-word;
    max-width: 100%;
}

@media (max-width: 1200px) {
    .media-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .media-grid {
        grid-template-columns: 1fr;
    }
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 1rem;
    transition: background-color 0.2s ease;
}

.section-header:hover {
    background: #e9ecef;
}

.section-header h2 {
    margin: 0;
    border: none;
    padding: 0;
}

.section-header i {
    font-size: 1.2rem;
    color: #6c757d;
    transition: transform 0.3s ease;
}

.section-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    opacity: 0;
    visibility: hidden;
}

.section-content.open {
    max-height: none;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in;
    padding: 1rem 0;
}

.media-section {
    margin-bottom: 2rem;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    overflow: visible;
}

.media-section h2 {
    color: #333;
    margin: 0;
    padding: 0;
    border: none;
}

/* Add scrollbar styling */
.media-grid::-webkit-scrollbar {
    width: 8px;
}

.media-grid::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.media-grid::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.media-grid::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Upload section styles */
.upload-section {
    margin: 2rem 0;
}

.upload-box {
    border: 2px dashed #ccc;
    padding: 2rem;
    text-align: center;
    border-radius: 8px;
    background: #f8f9fa;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.upload-box:hover {
    border-color: #007bff;
}

.upload-box i {
    font-size: 3rem;
    color: #6c757d;
    margin-bottom: 1rem;
}

.file-input {
    display: none;
}

.selected-files {
    margin-top: 1rem;
    text-align: left;
}

.selected-files ul {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
}

.selected-files li {
    padding: 0.5rem;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 0.5rem;
}

.upload-button {
    background: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.upload-button:hover {
    background: #0056b3;
}

.upload-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

/* Ensure audio controls are visible and clickable */
audio::-webkit-media-controls-panel {
    background-color: #f8f9fa;
}

audio::-webkit-media-controls {
    display: flex !important;
    opacity: 1 !important;
}

.audio-info {
    width: 100%;
    text-align: center;
    margin-top: 10px;
} 