/* Global box sizing */
*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  /* Primary Colors */
  --primary-color: #76B586;
  /* --primary-color: #75B686; */
  --primary-dark: #5f9e6f;
  --primary-light: #C6D5C7;
  /* --primary-light: #a3d1af; */

  /* Secondary Colors */
  --secondary-color: #FAEE55;
  --secondary-dark: #d9ce3d;
  --secondary-light: #fbf288;


  /* Text Colors */
  --text-dark: #333333;
  --text-light: #ffffff;
  --text-muted: #666666;

  /* Accent Colors */
  --accent-success: #28a745;
  --accent-error: #dc3545;
  --accent-warning: #ffc107;
  --accent-info: #17a2b8;

  /* Background Colors */
  --bg-light: #f8f9fa;
  --bg-dark: #343a40;
  --bg-muted: #e9ecef;

  /* Border Colors */
  --border-light: #e2e8f0;
  --border-dark: #4a5568;
  --border-muted: #cbd5e0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Dashboard Styles */
.dashboard-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.dashboard-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-box {
  background: white;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  min-height: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  width: 100%;
  border: 2px solid var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.dashboard-box i {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.dashboard-box h2 {
  color: var(--text-dark);
  margin: 1rem 0;
  font-size: 1.5rem;
}

.dashboard-box p {
  color: #666;
  margin: 0;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

.dashboard-layout {
    display: flex;
    min-height: 100vh;
}

.sidebar {
    width: 250px;
    background-color: white;
    border-right: 1px solid #eee;
    padding: 1rem;
    position: sticky;
    top: 0;
    height: 100vh;
}

.sidebar-header {
    padding: 1rem;
    border-bottom: 2px solid var(--primary-color);
}

.sidebar-nav {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
}

.sidebar-nav li {
    margin: 0.5rem 0;
}

.sidebar-nav a {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    color: var(--text-dark);
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.2s;
}

.sidebar-nav a:hover {
    background-color: rgba(117, 182, 134, 0.1);
}

.sidebar-nav i {
    margin-right: 0.75rem;
    color: var(--primary-color);
}

.dashboard-container {
    flex: 1;
    padding: 2rem;
}

/* Add after existing styles */

.page-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.page-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.back-button {
    display: flex;
    align-items: center;
    color: var(--primary-color);
    text-decoration: none;
    margin-right: 1rem;
}

.back-button i {
    margin-right: 0.5rem;
}

.form-container {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-dark);
}

.form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.submit-button {
    background: var(--primary-color);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submit-button:hover {
    background-color: #63a374;
}

/* Add these new styles after your existing styles */

.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.form-group select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: white;
}

.form-actions {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
}

.cancel-button {
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    text-decoration: none;
    color: var(--text-dark);
    background-color: #f0f0f0;
    transition: background-color 0.2s;
}

.cancel-button:hover {
    background-color: #e0e0e0;
}

@media (max-width: 768px) {
    .form-grid {
        grid-template-columns: 1fr;
    }
}

/* Add after existing styles */

.programs-list .list-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
    margin-top: 20px;
}
.programs-list {
    margin-top: 2rem;
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.list-container {
    margin-top: 1rem;
}

.list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #eee;
}

.list-item:last-child {
    border-bottom: none;
}

.edit-button {
    background: none;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
    padding: 0.5rem;
}

.edit-button:hover {
    color: var(--secondary-color);
}

.edit-input {
    padding: 0.5rem;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    width: 80%;
}

/* Add these new styles */

.list-item-actions {
    display: flex;
    gap: 0.5rem;
}

.delete-button {
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    padding: 0.5rem;
}

.delete-button:hover {
    color: #c82333;
    color: var(--text-light);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 90%;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;
}

.modal-content h3 {
    margin-top: 0;
    color: var(--text-dark);
}

.modal-content p {
    color: #666;
}

.modal-actions .delete-button {
    background-color: #dc3545;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.modal-actions .delete-button:hover {
    background-color: #c82333;
}

/* Add these new styles for tags */
.tags-input-container {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0.5rem;
    background: white;
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.tag {
    background: var(--primary-color);
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.tag-remove {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 0 0.25rem;
    font-size: 1.2rem;
}

.tag-suggestions {
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    width: calc(100% - 2rem);
    z-index: 1000;
}

.tag-suggestion {
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.tag-suggestion:hover {
    background: #f0f0f0;
}

/* Add these new styles */
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    font-size: 1.2rem;
    color: var(--primary-color);
}

.error-message {
    padding: 1rem;
    margin: 1rem;
    background-color: #fee;
    border: 1px solid #fcc;
    border-radius: 5px;
    color: #c00;
}

/* Add these new styles for activities list */
.activities-list {
    margin-top: 2rem;
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.activity-info {
    flex: 1;
}

.activity-info h3 {
    margin: 0;
    color: var(--text-dark);
    font-size: 1.2rem;
}

.program-name {
    color: #666;
    margin: 0.5rem 0;
    font-size: 0.9rem;
}

.activity-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.activity-tags .tag {
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    background: rgba(117, 182, 134, 0.1);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.success-message {
    margin: 1rem 0;
    padding: 1rem;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 5px;
    color: #155724;
}

.list-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    border-bottom: 1px solid #eee;
}

.list-item:last-child {
    border-bottom: none;
}

.list-item-actions {
    display: flex;
    gap: 0.5rem;
}

/* Add these new styles for editing */
.edit-form {
    width: 100%;
}

.edit-input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
}

.edit-select {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
}

.edit-tags {
    margin-top: 0.5rem;
}

.save-button {
    background: none;
    border: none;
    color: #28a745;
    cursor: pointer;
    padding: 0.5rem;
}

.save-button:hover {
    color: #218838;
}

.cancel-button {
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    padding: 0.5rem;
}

.cancel-button:hover {
    color: #c82333;
}

.edit-tag-input {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    font-size: 0.9rem;
}

.edit-tags .tags-input-container {
    margin-top: 0.5rem;
    position: relative;
}

.edit-tags .tag-suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
}

.form-textarea, .edit-textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: vertical;
    min-height: 100px;
    font-family: inherit;
}

.edit-textarea {
    border-color: var(--primary-color);
    margin-bottom: 0.5rem;
}

.activity-description {
    color: #666;
    margin: 0.5rem 0;
    font-size: 0.9rem;
    white-space: pre-wrap;
}

.activity-description-container {
    position: relative;
    margin: 0.5rem 0;
}

.show-more-button {
    background: none;
    border: none;
    color: var(--primary-color);
    padding: 0;
    font-size: 0.8rem;
    cursor: pointer;
    /* text-decoration: underline; */
    margin-top: 0.25rem;
}

.show-more-button:hover {
    color: #63a374;
}

.activity-description {
    color: #666;
    margin: 0.5rem 0;
    font-size: 0.9rem;
    white-space: pre-wrap;
    line-height: 1.4;
}

.page-header-stack {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}

.page-header-stack h1 {
    margin: 0;
    text-align: center;
}

.search-container {
    position: relative;
    width: 100%;
}

.search-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

.search-result-item {
    padding: 8px;
    cursor: pointer;
}

.search-result-item:hover {
    background-color: #f0f0f0;
}

.selected-activity {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #f0f0f0;
    padding: 4px 8px;
    border-radius: 4px;
    margin: 4px 0;
}

.activity-order-controls {
    display: flex;
    gap: 4px;
}

.activity-order-controls button {
    padding: 2px 6px;
    background: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.activity-order-controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.activities-list-container {
    min-height: 50px;
    padding: 8px 0;
}

.selected-activity {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: white;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 4px 0;
    cursor: grab;
}

.selected-activity.dragging {
    background-color: #f8f8f8;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.drag-handle {
    color: #999;
    cursor: grab;
}

.selected-activity button {
    margin-left: auto;
    background: none;
    border: none;
    color: #999;
    cursor: pointer;
    padding: 4px 8px;
}

.selected-activity button:hover {
    color: #666;
}

/* Add these styles for the playlist activities list */
.playlist-activities {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;
    padding-left: 20px;
}

.activity-tag {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    background-color: #f5f5f5;
    border-radius: 4px;
    font-size: 0.9em;
    position: relative;
}

.activity-tag::before {
    content: counter(activity-counter);
    counter-increment: activity-counter;
    position: absolute;
    left: -25px;
    font-weight: bold;
    color: #666;
}

/* Update the playlist info container */
.playlist-info {
    flex: 1;
    padding: 15px;
    counter-reset: activity-counter;
}

/* Add these new styles for section organization */
.section-container {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.section-container h2 {
    color: var(--text-dark);
    margin-top: 0;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--primary-color);
    margin-bottom: 1.5rem;
}

.section-divider {
    height: 2px;
    background: #eee;
    margin: 2rem 0;
}

/* Update existing styles */
.businesses-list {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #eee;
}

.list-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

.list-item {
    background: #f8f8f8;
    border-radius: 8px;
    padding: 1rem;
    border: 1px solid #eee;
}

.business-info h3, .user-info h3 {
    color: var(--primary-color);
    margin: 0 0 0.5rem 0;
}

.business-info p, .user-info p {
    margin: 0.25rem 0;
    color: #666;
}

/* Update form styles */
.form-container {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

/* Update message styles */
.success-message, .error-message {
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
}

/* Update edit form styles */
.edit-form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    padding: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.edit-input, .edit-select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 0.5rem;
}

/* Add styles for section headers */
.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.section-header h2 {
    margin: 0;
    border: none;
    padding: 0;
}

/* Add responsive adjustments */
@media (max-width: 768px) {
    .list-container {
        grid-template-columns: 1fr;
    }

    .form-grid {
        grid-template-columns: 1fr;
    }

    .edit-form {
        grid-template-columns: 1fr;
    }
}

/* Add styles for the view all link */
.view-all-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.view-all-link:hover {
    background-color: rgba(117, 182, 134, 0.1);
}

.view-all-link i {
    font-size: 0.9em;
}

/* Update section header styles */
.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.section-header h2 {
    margin: 0;
    padding: 0;
    border: none;
}

/* Update list container for better spacing */
.list-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
}

/* Add some hover effect to list items */
.list-item {
    transition: transform 0.2s, box-shadow 0.2s;
}

.list-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Detail page styles */
.detail-card {
    background: white;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
}

.detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--primary-color);
}

.detail-header h2 {
    margin: 0;
    color: var(--text-dark);
}

.role-badge, .seats-badge {
    background-color: var(--primary-color);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
}

.detail-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.detail-row {
    display: flex;
    gap: 1rem;
    padding: 0.5rem 0;
}

.detail-row label {
    font-weight: 500;
    min-width: 120px;
    color: #666;
}

.detail-section {
    margin-top: 2rem;
}

.detail-section h3 {
    color: var(--text-dark);
    margin-bottom: 1rem;
}

.associated-users {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    background-color: #f8f8f8;
    border-radius: 4px;
}

.user-role {
    font-size: 0.9rem;
    color: #666;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    font-size: 1.2rem;
    color: var(--primary-color);
}

/* Add styles for clickable items */
.list-item.clickable {
    cursor: pointer;
    position: relative;
    padding-right: 2rem;
}

.list-item.clickable::after {
    content: '\f054';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--primary-color);
    opacity: 0;
    transition: opacity 0.2s, transform 0.2s;
}

.list-item.clickable:hover::after {
    opacity: 1;
    transform: translate(5px, -50%);
}

.list-item.clickable:hover {
    background-color: #f0f0f0;
}


a {
    text-decoration: none;
}

.wrapper {
    /* background-color: var(--primary-light); */
}