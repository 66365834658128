/* Translation Dashboard Styles */
.translation-dashboard {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.translation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--primary-color);
}

.translation-header h1 {
  color: var(--text-dark);
  margin: 0;
}

.translation-tables {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.translation-tables h2 {
  color: var(--text-dark);
  margin: 0 0 1rem 0;
  font-size: 1.25rem;
}

.table-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.table-button {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.table-button.active {
  background-color: var(--primary-color);
  color: var(--text-light);
}

.table-button:not(.active) {
  background-color: var(--bg-muted);
  color: var(--text-dark);
}

.table-button:hover:not(.active) {
  background-color: var(--primary-light);
  color: var(--text-light);
}

.translation-content {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.content-header h2 {
  color: var(--text-dark);
  margin: 0;
  font-size: 1.25rem;
}

.translation-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5rem;
  background: var(--bg-light);
  border-radius: 8px;
  overflow: hidden;
}

.translation-table th {
  background-color: var(--primary-color);
  color: var(--text-light);
  padding: 1rem;
  text-align: left;
  font-weight: 500;
}

.translation-table td {
  padding: 1rem;
  border: 1px solid var(--border-light);
}

.translation-table tr:nth-child(even) {
  background-color: var(--bg-muted);
}

.field-name {
  font-weight: 600;
  color: var(--text-dark);
  min-width: 150px;
}

.translation-input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid var(--border-light);
  border-radius: 5px;
  font-size: 0.95rem;
  transition: border-color 0.2s ease;
}

.translation-input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.translation-textarea {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid var(--border-light);
  border-radius: 5px;
  font-size: 0.95rem;
  min-height: 120px;
  resize: vertical;
  transition: border-color 0.2s ease;
}

.translation-textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

.item-header {
  background-color: var(--bg-light);
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  border-left: 4px solid var(--primary-color);
}

.item-header h3 {
  color: var(--text-dark);
  margin: 0;
  font-size: 1.1rem;
}

.save-button {
  background-color: var(--accent-success);
  color: var(--text-light);
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.save-button:hover {
  background-color: #218838;
}

.initialize-button {
  background-color: var(--primary-color);
  color: var(--text-light);
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.initialize-button:hover {
  background-color: var(--primary-dark);
}

@media (max-width: 768px) {
  .translation-dashboard {
    padding: 1rem;
  }

  .translation-header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .table-buttons {
    gap: 0.5rem;
  }

  .table-button {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }

  .translation-table th,
  .translation-table td {
    padding: 0.75rem;
  }
} 