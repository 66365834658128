.mailerlite-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.page-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.back-button {
    text-decoration: none;
    color: #666;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.mailerlite-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

/* Newsletter Section Styles */
.newsletter-section {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #eee;
}

.newsletter-section h3 {
    color: #333;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.newsletter-section h3 i {
    color: #6c757d;
    font-size: 0.9em;
}

/* Form Styles */
.newsletter-form {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 8px;
    margin-top: 1rem;
    border: 1px solid #e2e8f0;
}

.newsletter-form .form-group {
    margin-bottom: 1rem;
}

.newsletter-form select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background-color: white;
    font-size: 0.9rem;
    color: #475569;
}

.newsletter-form select:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.1);
}

.newsletter-form .form-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

/* Group List Styles */
.groups-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 1.5rem;
    width: 100%;
}

.group-actions {
    display: flex;
    gap: 1rem;
}

.group-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #f8fafc;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    border: 1px solid #e2e8f0;
    transition: all 0.2s ease;
    margin-bottom: 0.5rem;
}

.group-badge:hover {
    background-color: #f1f5f9;
}

/* Button Styles */
.secondary-button {
    background-color: #f1f5f9;
    color: #475569;
    border: 1px solid #e2e8f0;
    min-width: 120px;
    height: 36px;
    padding: 0 1rem;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.secondary-button:hover {
    background-color: #e2e8f0;
}

.remove-group-button {
    background: none;
    border: none;
    color: #94a3b8;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: all 0.2s ease;
    margin-left: 0.25rem;
}

.remove-group-button:hover {
    background-color: #fef2f2;
    color: #ef4444;
}

.remove-group-button i {
    font-size: 0.8rem;
}

/* Section Styles */
.groups-section,
.subscribers-section {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Form Styles */
.create-group-form,
.add-subscriber-form {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.mailerlite-container input {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex: 1;
}

/* Message Styles */
.success-message {
    background: #d4edda;
    color: #155724;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.error-message {
    background: #f8d7da;
    color: #721c24;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.no-groups {
    color: #6c757d;
    font-style: italic;
    margin-top: 0.75rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 8px;
    text-align: center;
}

/* Action Buttons */
.mailerlite-container .create-group-form button,
.mailerlite-container .add-subscriber-form button,
.mailerlite-container .view-button,
.mailerlite-container .delete-button,
.mailerlite-container .secondary-button {
    min-width: 120px;
    height: 36px;
    padding: 0 1rem;
    border: none;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Primary button style */
.mailerlite-container .create-group-form button,
.mailerlite-container .add-subscriber-form button {
    background-color: var(--primary-color);
    color: white;
}

.mailerlite-container .create-group-form button:hover,
.mailerlite-container .add-subscriber-form button:hover {
    background-color: var(--primary-color);
}

/* View button style */
.mailerlite-container .view-button {
    background-color: var(--primary-color);
    color: white;
}

.mailerlite-container .view-button:hover {
    background-color: var(--primary-color);
}

/* Delete button style */
.mailerlite-container .delete-button {
    background-color: #e57373;
    color: white;
}

.mailerlite-container .delete-button:hover {
    background-color: #d32f2f;
    color: var(--text-light);
}

/* Secondary button style */
.mailerlite-container .secondary-button {
    background-color: #f1f5f9;
    color: #475569;
    border: 1px solid #e2e8f0;
}

.mailerlite-container .secondary-button:hover {
    background-color: #e2e8f0;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .mailerlite-grid {
        grid-template-columns: 1fr;
    }

    .create-group-form,
    .add-subscriber-form {
        flex-direction: column;
    }
}

.subscriber-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
}

.subscriber-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.subscriber-info {
    display: flex;
    align-items: center;
}

.subscriber-details {
    flex: 1;
}

.subscriber-details .phone,
.subscriber-details .experience-level {
    color: #666;
    font-size: 0.9rem;
    margin-top: 0.25rem;
}

/* Add these styles to your existing MailerLite.css */

.newapp-section {
    margin-top: 2rem;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.newapp-section h2 {
    color: #333;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 0.5rem;
}

.newapp-subscribers-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
}

.newapp-subscriber-card {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    transition: transform 0.2s ease;
}

.newapp-subscriber-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.newapp-subscriber-card .subscriber-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.newapp-subscriber-card h3 {
    color: #2d3748;
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
}

.newapp-subscriber-card p {
    color: #4a5568;
    margin: 0;
    font-size: 0.95rem;
}

.newapp-subscriber-card .phone {
    color: #718096;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.newapp-subscriber-card .phone::before {
    content: '\f095';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.subscriber-images {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    overflow-x: auto;
    padding: 0.5rem 0;
}

.subscriber-images::-webkit-scrollbar {
    height: 6px;
}

.subscriber-images::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
}

.subscriber-images::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 3px;
}

.subscriber-images::-webkit-scrollbar-thumb:hover {
    background: #999;
}

.subscriber-images img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s ease;
}

.subscriber-images img:hover {
    transform: scale(1.05);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .newapp-section {
        padding: 1rem;
    }

    .newapp-subscribers-grid {
        grid-template-columns: 1fr;
    }

    .subscriber-images img {
        width: 100px;
        height: 100px;
    }
}

/* Image Modal Styles */
.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 2rem;
}

.image-modal-content {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    position: relative;
    width: 50%;
    max-height: 90vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.image-modal-content img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 4px;
}

.modal-close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    color: #666;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    transition: all 0.2s ease;
}

.modal-close-button:hover {
    background: #f1f5f9;
    color: #333;
}

.image-info {
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 4px;
}

.image-info p {
    margin: 0.25rem 0;
    color: #666;
    font-size: 0.9rem;
}

/* Make subscriber images clickable */
.subscriber-image {
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.subscriber-image:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .image-modal-content {
        width: 90%;
        margin: 1rem;
    }
}

.campaign-section {
    margin-top: 2rem;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.campaign-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.groups-select {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 0.5rem;
}

.group-checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    background: #f8f9fa;
    border-radius: 4px;
    cursor: pointer;
}

.create-campaign-button {
    background: var(--primary-color);
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.create-campaign-button:hover {
    background-color:var(--primary-color);
}

@media (max-width: 768px) {
    .form-row {
        grid-template-columns: 1fr;
    }
} 