/* Update/add these styles to match your existing input fields */
.form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid #e2e8f0;
    border-radius: 0.375rem;
    font-size: 1rem;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    min-height: 100px;
    resize: vertical;
}

.form-group textarea:focus {
    outline: none;
    border-color: #3182ce;
    box-shadow: 0 0 0 1px #3182ce;
}

.form-group textarea:hover {
    border-color: #cbd5e0;
}

.form-group textarea::placeholder {
    color: var(--text-muted);
}

.form-group input[type="file"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
}

/* Style for the edit form */
.edit-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.edit-form-row {
    display: flex;
    gap: 20px;
    width: 100%;
}

.edit-form-col {
    flex: 1;
    min-width: calc(50% - 10px);
}

.toggle-group {
    display: flex;
    flex-direction: row;
    gap: 50px;
}
.toggle-label {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
}

.tooltip-icon {
    color: #666;
    cursor: help;
    margin-left: 8px;
}

/* Tooltip styling */
.tooltip-icon:hover::after {
    /* content: attr(title); */
    content: attr(data-tooltip);
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    width: max-content;
    max-width: 200px;
    z-index: 1000;
}
.edit-form input,
.edit-form select,
.edit-form .toggle-label {
    width: 100%;
}

.edit-form textarea,
.edit-form .media-selector {
    width: 100%;
    grid-column: 1 / -1;
}

.edit-form .toggle-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.edit-form textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    resize: vertical;
    min-height: 60px;
}

.edit-form input[type="file"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Program display styles */
.program-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.program-name {
    font-weight: 600;
    font-size: 16px;
}

.program-description {
    font-size: 14px;
    color: #666;
    margin: 0;
    line-height: 1.4;
}

.program-image {
    max-width: 200px;
    max-height: 150px;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #ddd;
}

/* Hover effects */
.form-group textarea:hover,
.form-group input[type="file"]:hover,
.edit-form textarea:hover,
.edit-form input[type="file"]:hover {
    border-color: #b3b3b3;
}

.selected-programs {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 8px;
}

.selected-program-tag {
    display: inline-flex;
    align-items: center;
    background-color: #e2e8f0;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
}

.selected-program-tag button {
    background: none;
    border: none;
    margin-left: 6px;
    cursor: pointer;
    padding: 0 4px;
    color: #666;
}

.selected-program-tag button:hover {
    color: #ef4444;
}

.multi-select {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid #e2e8f0;
    border-radius: 0.375rem;
    background-color: white;
    min-height: 100px;
}

.multi-select option {
    padding: 8px;
}

.multi-select option:checked {
    background-color: #3182ce;
    color: white;
}

.select-hint {
    display: block;
    color: #666;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    font-style: italic;
}

.select-hint i {
    margin-right: 0.5rem;
    color: #3182ce;
}

.form-group input[type="color"] {
    width: 100%;
    height: 40px;
    padding: 2px;
    border: 2px solid #e2e8f0;
    border-radius: 0.375rem;
    background-color: #fff;
}

.level-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.level-description {
    font-size: 14px;
    color: #666;
    margin: 0;
}

.color-inputs-row {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
}

.color-input-group {
    flex: 1;
    max-width: 200px;
}

.color-input-group input[type="color"] {
    width: 100%;
    height: 40px;
    padding: 2px;
}

.form-select {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid #e2e8f0;
    border-radius: 0.375rem;
    background-color: white;
    font-size: 1rem;
}

.form-select option {
    padding: 8px;
}

.form-select:focus {
    outline: none;
    border-color: #3182ce;
    box-shadow: 0 0 0 1px #3182ce;
}

.activity-detail {
    font-size: 14px;
    color: #4a5568;
    margin: 4px 0;
}

.activity-detail strong {
    color: #2d3748;
    margin-right: 8px;
}

.toggle-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 5px 0;
}

.toggle-text {
    font-size: 1rem;
    color: #2d3748;
}

.toggle-switch {
    position: relative;
    width: 60px;
    height: 34px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.toggle-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .toggle-slider {
    background-color: #3182ce;
}

input:checked + .toggle-slider:before {
    transform: translateX(26px);
}

.subscription-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.subscription-description {
    font-size: 14px;
    color: #666;
    margin: 0;
}

.subscription-price {
    font-size: 18px;
    font-weight: 600;
    color: #2d3748;
}

.subscription-duration {
    font-size: 14px;
    color: #4a5568;
}

.business-subscription {
    font-size: 14px;
    color: #4a5568;
    margin-top: 4px;
    font-weight: 500;
}

.form-select {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid #e2e8f0;
    border-radius: 0.375rem;
    background-color: white;
    font-size: 1rem;
}

.form-select:focus {
    outline: none;
    border-color: #3182ce;
    box-shadow: 0 0 0 1px #3182ce;
}

.subscription-details {
    margin: 1.5rem 0;
    padding: 1rem;
    background-color: #f7fafc;
    border-radius: 0.5rem;
    border: 1px solid #e2e8f0;
}

.subscription-details h3 {
    color: #2d3748;
    margin-bottom: 1rem;
    font-size: 1.1rem;
}

.detail-row {
    display: flex;
    margin: 0.5rem 0;
    align-items: center;
}

.detail-row label {
    font-weight: 600;
    color: #4a5568;
    width: 120px;
    margin-right: 1rem;
}

.detail-row span {
    color: #2d3748;
}

.upload-section {
    margin: 20px 0;
}

.upload-box {
    border: 2px dashed #ccc;
    padding: 40px;
    text-align: center;
    background: #f9f9f9;
    border-radius: 8px;
    cursor: pointer;
}

.upload-box:hover {
    border-color: #666;
}

.file-input {
    display: none;
}

.selected-files {
    margin-top: 20px;
}

.selected-files ul {
    list-style: none;
    padding: 0;
}

.selected-files li {
    margin: 5px 0;
}

.upload-button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.upload-button:disabled {
    background-color: #ccc;
}

.media-gallery {
    margin-top: 40px;
}

.media-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 20px;
}

.media-option {
    padding: 15px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.media-option:hover {
    background-color: #f5f5f5;
}

.media-option audio {
    width: 300px;
}

.media-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 400px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
    width: 100%;
}

.media-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    padding: 10px;
}

.media-item img,
.media-item video {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.media-item p {
    margin: 10px 0;
    font-size: 0.9em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.media-selector {
    position: relative;
    margin-bottom: 20px;
}

.search-container {
    position: relative;
    margin-bottom: 8px;
    width: 100%;
}

.search-input {
    width: 100%;
    padding: 10px 12px;
    border: 2px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.95rem;
    transition: all 0.2s ease;
    margin-bottom: 0;
}

.search-input:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 1px #4299e1;
}

.search-results {
    position: relative;
    width: 100%;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-result-item {
    padding: 10px 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
}

.search-result-item:last-child {
    border-bottom: none;
}

.search-result-item:hover {
    background: #f7fafc;
}

.selected-media {
    margin-top: 10px;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 4px;
}

.selected-media p {
    margin-bottom: 5px;
    font-weight: 500;
}

.activities-list {
    margin-top: 40px;
    width: 100%;
    /* width: 200px; */
}

.list-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 25px;
    margin-top: 20px;
}

.list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.activity-info {
    flex: 1;
    margin-right: 20px;
}

.list-item-actions {
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.activity-description-container {
    margin: 10px 0;
}

.activity-description {
    color: #4a5568;
    line-height: 1.5;
}

.activity-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: 10px 0;
}

.edit-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Programs List Styling */
.programs-list {
    margin-top: 40px;
    padding: 20px;
}

.programs-list h2 {
    color: #2c3e50;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #e2e8f0;
}

.list-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 25px;
    margin-top: 20px;
}

.program-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    overflow: hidden;
}

.program-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.program-header {
    width: 100%;
    padding: 20px;
    background: #f8f9fa;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.program-header h3 {
    margin: 0;
    color: #2c3e50;
    font-size: 1.5rem;
}

.program-description {
    padding: 15px 20px;
    color: #666;
    font-size: 0.95rem;
    line-height: 1.5;
    border-bottom: 1px solid #eee;
}

.program-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #eee;
}

.program-actions {
    display: flex;
    gap: 10px;
}

.edit-button, .delete-button {
    padding: 8px 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.edit-button {
    background: #4CAF50;
    color: white;
}

.edit-button:hover {
    background: var(--secondary-color);
    color: var(--text-black)
}

.delete-button {
    background: #f44336;
    color: white;
}

.delete-button:hover {
    background: #da190b;
    color: var(--text-light)
}

/* Edit Form Styling */
.edit-form {
    padding: 20px;
}

.edit-input, .edit-textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 1rem;
}

.edit-textarea {
    min-height: 100px;
    resize: vertical;
}

.edit-file-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
}

.edit-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: flex-end;
}

.edit-actions button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: background-color 0.2s;
}

.edit-actions .save-button {
    background-color: #4CAF50;
    color: white;
}

.edit-actions .save-button:hover {
    background-color: #45a049;
}

.edit-actions .cancel-button {
    background-color: #9e9e9e;
    color: white;
}

.edit-actions .cancel-button:hover {
    background-color: #757575;
}

.edit-actions button i {
    font-size: 0.9rem;
}

/* Program Content Styling */
.program-content {
    padding: 20px;
}

.week-section {
    margin-bottom: 25px;
}

.week-title {
    color: #2c3e50;
    font-size: 1.2rem;
    margin-bottom: 15px;
    padding-bottom: 8px;
    border-bottom: 2px solid #4CAF50;
}

.days-grid {
    display: flex;
    /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
    gap: 15px;
}

.day-card {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 15px;
    border: 1px solid #eee;
    min-width: 250px;
}

.day-title {
    color: #2c3e50;
    margin: 0 0 12px 0;
    font-size: 1.1rem;
}

.activities-list .programs-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.activity-card {
    background: white;
    border-radius: 6px;
    padding: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.activity-name {
    font-weight: 500;
    color: #2c3e50;
}

.activity-description {
    margin-top: 8px;
    font-size: 0.9rem;
    color: #666;
}

.no-activities {
    color: #666;
    font-style: italic;
    text-align: center;
    padding: 10px;
}

.no-programs {
    text-align: center;
    padding: 40px;
    color: #666;
    font-style: italic;
    grid-column: 1 / -1;
}

/* Tier Section Styling */
.tiers-section {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    border: 1px solid #e2e8f0;
}

.add-tier-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    transition: background-color 0.2s;
}

.add-tier-button:hover {
    background-color: #45a049;
}

.add-tier-button::before {
    content: "+";
    font-size: 18px;
    font-weight: bold;
}

.tier-form {
    background: white;
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 15px;
    border: 1px solid #e2e8f0;
    position: relative;
}

.remove-tier-button {
    background-color: #dc3545;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 15px;
    transition: background-color 0.2s;
}

.remove-tier-button:hover {
    background-color: #c82333;
}

.remove-tier-button::before {
    content: "×";
    font-size: 18px;
    font-weight: bold;
}

.tier-form .form-group {
    margin-bottom: 15px;
}

.tier-form input {
    background-color: #f8f9fa;
}

.tier-form label {
    color: #2d3748;
    font-weight: 500;
}

/* Update the tier form amount field styling */
.tier-form .amount-field-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.tier-form .amount-field-container .form-group {
    flex: 1;
    margin-bottom: 0;
}

.tier-form .amount-field-container .toggle-label {
    padding: 0;
    margin-top: 25px; /* Align with input field, accounting for label height */
}

.tier-form .amount-field-container .toggle-switch {
    width: 50px;
    height: 28px;
}

.tier-form .amount-field-container .toggle-slider:before {
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
}

.tier-form .amount-field-container input[type="checkbox"]:checked + .toggle-slider:before {
    transform: translateX(22px);
}

.tier-form .amount-field-container .toggle-label span {
    font-size: 0.9em;
    color: #666;
    white-space: nowrap;
}

.input-with-toggle {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.input-with-toggle input[type="number"] {
    flex: 1;
    min-width: 0; /* Prevents input from overflowing */
}

.input-with-toggle .toggle-label {
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    margin: 0;
    padding: 0;
}

.input-with-toggle .toggle-switch {
    width: 44px;
    height: 24px;
    margin-left: 8px;
}

.input-with-toggle .toggle-slider:before {
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
}

.input-with-toggle input[type="checkbox"]:checked + .toggle-slider:before {
    transform: translateX(20px);
}

.tier-form .form-group {
    margin-bottom: 20px;
}

.tier-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
}

/* Align the toggle with the input field */
.tier-form .input-with-toggle {
    height: 38px; /* Match input height */
    align-items: center;
}

/* Make the toggle text more visible */
.tier-form .toggle-label span {
    color: #4a5568;
    font-size: 0.9rem;
    font-weight: 500;
}

.subscription-tiers {
    margin-top: 15px;
    padding: 15px;
    background: #f8f9fa;
    border-radius: 6px;
}

.subscription-tiers h4 {
    margin: 0 0 10px 0;
    color: #2d3748;
}

.tiers-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.tier-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background: white;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
}

.tier-minutes {
    color: #4a5568;
    font-weight: 500;
}

.tier-amount {
    color: #2d3748;
    font-weight: 600;
}

.subscription-details {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #e2e8f0;
    font-size: 0.9em;
    color: #666;
}

.subscription-interval,
.subscription-mva,
.subscription-stripe-id {
    margin: 5px 0;
}

.subscription-stripe-id {
    font-family: monospace;
    font-size: 0.85em;
    color: #718096;
}

/* Image styles */
.program-image-container {
    width: 100%;
    margin: 1rem 0;
    cursor: pointer;
    transition: transform 0.2s;
}

.program-thumbnail {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.program-image-container:hover {
    transform: scale(1.02);
}

/* Modal styles */
.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-modal-content {
    position: relative;
    max-width: 90vw;
    max-height: 90vh;
    background: white;
    padding: 1rem;
    border-radius: 8px;
}

.image-modal-content img {
    max-width: 100%;
    max-height: calc(90vh - 2rem);
    object-fit: contain;
}

.close-button {
    position: absolute;
    top: -1rem;
    right: -1rem;
    background: white;
    border: none;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

.close-button:hover {
    background-color: #f0f0f0;
}

.selected-activities {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 15px 0;
    background: #f8f9fa;
    border-radius: 8px;
    padding: 12px;
    border: 1px solid #e2e8f0;
}

.selected-activity-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background: white;
    border-radius: 6px;
    border: 1px solid #e2e8f0;
    transition: all 0.2s ease;
}

.selected-activity-item:hover {
    border-color: #cbd5e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.selected-activity-item span {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.95rem;
    color: #2d3748;
}

.selected-activity-item span::before {
    /* content: "≡"; */
    color: #718096;
    font-size: 1.2rem;
}

.selected-activity-item .remove-button {
    background: none;
    border: none;
    padding: 4px 8px;
    color: #718096;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
}

.selected-activity-item .remove-button:hover {
    background: #f7fafc;
    color: #e53e3e;
}

.selected-activity-item .remove-button i {
    font-size: 0.9rem;
}

/* Search container styles */
.search-container {
    position: relative;
    margin-bottom: 8px;
    width: 100%;
}

.search-input {
    width: 100%;
    padding: 10px 12px;
    border: 2px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.95rem;
    transition: all 0.2s ease;
    margin-bottom: 0;
}

.search-input:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 1px #4299e1;
}

.search-results {
    position: relative;
    width: 100%;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-result-item {
    padding: 10px 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
}

.search-result-item:last-child {
    border-bottom: none;
}

.search-result-item:hover {
    background: #f7fafc;
}

/* Edit form specific styles */
.edit-form .selected-activities {
    margin: 10px 0;
}

.edit-form .selected-activity-item {
    background: #f8f9fa;
}

.edit-form .search-container {
    margin-bottom: 15px;
}

/* Form group spacing */
.form-group + .selected-activities {
    margin-top: 20px;
}

.selected-activities + .form-actions {
    margin-top: 20px;
}

/* Program card adjustments */
.program-card .selected-activities {
    background: transparent;
    padding: 0;
    border: none;
}

.program-card .selected-activity-item {
    background: #f8f9fa;
    margin-bottom: 8px;
}

/* Activity tags in view mode */
.activity-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;
}

.activity-tags .selected-program-tag {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 6px 10px;
    background: #f7fafc;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-size: 0.85rem;
    color: #4a5568;
}

.activity-tags .selected-program-tag i {
    color: #718096;
    font-size: 0.9rem;
}

.week-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
}

.week-header h3 {
    margin: 0;
    color: #2d3748;
    font-size: 1.25rem;
    font-weight: 600;
}

.week-header .delete-button,
.day-header .delete-button {
    background: none;
    border: none;
    color: #e53e3e;
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.week-header .delete-button:hover,
.day-header .delete-button:hover {
    background: #fed7d7;
}

.week-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.day-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
}

.day-header h4 {
    margin: 0;
    color: #2d3748;
    font-size: 1.1rem;
    /* font-weight: 500; */
}

.day-container {
    background: white;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    margin-bottom: 15px;
}
.day-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.week-description,
.day-description {
    margin: 10px 0;
}

.week-description input,
.day-description input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-size: 0.95rem;
    transition: all 0.2s ease;
}

.week-description input:focus,
.day-description input:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 1px #4299e1;
}

.add-button {
    background-color: #4CAF50;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
    transition: background-color 0.2s;
}

.add-button:hover {
    background-color: #45a049;
}

.add-button i {
    font-size: 0.9rem;
}

/* Album Styles */
.album-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.album-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.album-status {
  display: flex;
  align-items: center;
}

.status-badge {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-badge.published {
  background-color: #e6f4ea;
  color: #1e7e34;
}

.status-badge.draft {
  background-color: #fff3e0;
  color: #e65100;
}

.album-description {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.6;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.activity-count {
  font-size: 1.1rem;
  color: #666;
  background: #f5f5f5;
  padding: 8px 16px;
  border-radius: 20px;
}

.activities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.activity-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid #eee;
}

.activity-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.activity-card-content {
  flex: 1;
}

.activity-card h3 {
  margin: 0;
  color: #333;
  font-size: 1.2rem;
}

.activity-description {
  margin: 8px 0 0;
  color: #666;
  font-size: 0.9rem;
  line-height: 1.4;
}

.activity-card-actions {
  margin-left: 15px;
}

.view-activity-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.view-activity-button:hover {
  background-color: #f5f5f5;
  color: #333;
} 