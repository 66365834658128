.lopsteknikk-container {
    padding: 2rem;
}

.user-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
}

.user-card {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    transition: all 0.2s ease;
}

.user-card-content {
    flex: 1;
    cursor: pointer;
}

.user-card.selected {
    border: 2px solid var(--primary-color);
}

.user-card.feedback-sent {
    background-color: #f8fff8;
}

.delete-button {
    background: none;
    border: none;
    color: var(--accent-error);
    cursor: pointer;
    padding: 0.5rem;
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.delete-button:hover {
    opacity: 1;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 400px;
    width: 90%;
}

.modal-content h3 {
    margin-top: 0;
    color: var(--text-dark);
}

.modal-content p {
    color: var(--text-muted);
    margin-bottom: 1.5rem;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.modal-actions .cancel-button {
    background-color: var(--bg-muted);
    color: var(--text-dark);
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-actions .delete-button {
    background-color: var(--accent-error);
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    opacity: 1;
}

.modal-actions .delete-button:hover {
    background-color: #c82333;
}

.user-detail {
    padding: 2rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin-top: 2rem;
}

.user-info {
    margin-bottom: 2rem;
}

.video-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
}

.technique-video {
    /* max-width: 400px; */
    width: 100%;
    border-radius: 4px;
}

.feedback-section textarea {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

.feedback-section button {
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.feedback-section button:hover {
    background-color: var(--primary-dark);
}

.feedback-status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    color: var(--success-color, #28a745);
    font-size: 0.9rem;
}

.feedback-status i {
    font-size: 1rem;
}

.feedback-history {
    margin-top: 2rem;
    padding: 1.5rem;
    background-color: var(--background-light);
    border-radius: 8px;
}

.feedback-message {
    white-space: pre-wrap;
    margin: 1rem 0;
    padding: 1rem;
    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--border-color);
}

.feedback-date {
    color: var(--text-muted);
    font-size: 0.9rem;
    text-align: right;
}

.feedback-section button.sending {
    background-color: var(--primary-light);
    cursor: not-allowed;
    opacity: 0.8;
}

.feedback-section textarea:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.success-message,
.error-message {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 6px;
    text-align: center;
}

.success-message {
    background-color: var(--primary-color);
    color: var(--text-light);
}

.error-message {
    background-color: var(--text-muted);
    color: var(--text-light);
} 