.weekly-exercise-view {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
}

.exercise-header {
    text-align: center;
    margin-bottom: 3rem;
}

.exercise-header h1 {
    color: #2d3748;
    margin-bottom: 1rem;
}

.week-info {
    font-size: 1.2rem;
    color: #4a5568;
    font-weight: 500;
}

.exercise-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.content-type {
    margin-bottom: 1rem;
}

.content-name {
    margin-bottom: 2rem;
}

.content-name h2 {
    color: #2d3748;
    font-size: 1.8rem;
    margin: 0;
}

.content-details {
    border-top: 1px solid #e2e8f0;
    padding-top: 1.5rem;
}

.description {
    margin-bottom: 2rem;
}

.description h3 {
    color: #2d3748;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.description p {
    color: #4a5568;
    line-height: 1.6;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
}

.info-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.label {
    color: #718096;
    font-size: 0.9rem;
}

.value {
    color: #2d3748;
    font-weight: 500;
}

.exercise-footer {
    margin-top: 2rem;
    text-align: center;
}

.login-link {
    display: inline-block;
    background-color: #4a90e2;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    text-decoration: none;
    font-weight: 500;
    transition: background-color 0.2s;
}

.login-link:hover {
    background-color: #357abd;
}

.loading {
    text-align: center;
    padding: 3rem;
    color: #4a5568;
}

.error-message {
    text-align: center;
    padding: 2rem;
    color: #e53e3e;
    background: #fff5f5;
    border-radius: 8px;
    margin: 2rem auto;
    max-width: 400px;
}

.not-found {
    text-align: center;
    padding: 3rem;
    color: #4a5568;
    font-size: 1.2rem;
}

@media (max-width: 640px) {
    .weekly-exercise-view {
        padding: 1rem;
    }

    .exercise-content {
        padding: 1.5rem;
    }

    .info-grid {
        grid-template-columns: 1fr;
    }
} 