.profile-submission-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
}

.intro-text {
    color: var(  --text-muted);
    margin-bottom: 2rem;
    text-align: left;
    font-size: 1.1rem;
}

.profile-submission-container h1 {
    text-align: center;
}   

.profile-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(  --text-muted);
    font-weight: 500;
}

.form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(  --text-muted);
    border-radius: 6px;
    font-size: 0.95rem;
}

.file-upload {
    text-align: center;
    padding: 1rem;
    width: 100%;
}

.upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    min-height: 120px;
    background-color: transparent;
    color: var(--primary-color);
    border: 2px dashed var(--primary-color);
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.95rem;
    transition: all 0.2s ease;
}

.upload-button:hover {
    background-color: transparent;
    border-color: var(--primary-dark);
    color: var(--primary-dark);
}

.upload-button i {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.upload-button span {
    display: block;
}

.selected-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    color: var(  --text-muted);
}

.remove-file {
    background: none;
    border: none;
    color: var(  --text-muted);
    cursor: pointer;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.remove-file:hover {
    background-color: var(--primary-dark);
}

.submit-button {
    width: 100%;
    padding: 1rem;
    background-color: var(--primary-color);
    color: var(--text-light);
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submit-button:hover {
    background-color: var(--primary-dark);
}

.submit-button:disabled {
    background-color: var(  --text-muted);
    cursor: not-allowed;
}

.success-message,
.error-message {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 6px;
    text-align: center;
}

.success-message {
    background-color: var(--primary-color);
    color: var(--text-light);
}

.error-message {
    background-color: var(  --text-muted);
    color: var(--text-light);
}

/* Custom Checkbox Styling */
.container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 1rem;
    user-select: none;
    padding-left: 2.5rem;
    margin-bottom: 0.5rem;
    min-height: 1.5rem;
}

/* Hide the default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 1.3em;
    width: 1.3em;
    background-color: #ccc;
    border-radius: 50%;
    transition: 300ms;
}

/* When the checkbox is checked, add background */
.container input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border-radius: 0.5rem;
    animation: pulse 500ms ease-in-out;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 0.50em;
    top: 0.30em;
    width: 0.25em;
    height: 0.5em;
    border: solid var(--text-light);
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
}

.label-text {
    position: relative;
    top: 1px;
    color: var(--text-dark);
}
.payment-form {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.payment-form .StripeElement {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
}
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 var(--primary-color);
        rotate: 20deg;
    }

    50% {
        rotate: -20deg;
    }

    75% {
        box-shadow: 0 0 0 10px rgba(117, 182, 134, 0.4);
    }

    100% {
        box-shadow: 0 0 0 13px rgba(117, 182, 134, 0.2);
        rotate: 0;
    }
} 

.payment-form button.submit-button.sending {
    min-width: 250px;
    background-color: var(--primary-light);
    cursor: not-allowed;
    opacity: 0.8;
}
